(function (window) {
    // Создание основной функции MKQuery
    const MKQuery = function (selector) { return new MKQuery.fn.init(selector); };
    // Определение прототипа MKQuery
    MKQuery.fn = MKQuery.prototype = {
        constructor: MKQuery,

        /**
         * Метод init инициализирует библиотеку, заполняя свойство elements массивом, содержащим найденные элементы.
         *
         * @param {string|object} selector - Селектор (строка или объект) для выбора элементов в документе DOM.
         * @return {object} Возвращает текущий объект для дальнейшей работы с библиотекой.
         */
        init: function (selector) {
            // Инициализация метода init
            if (typeof selector === "string") this.elements = Array.from(document.querySelectorAll(selector));
            else if (typeof selector === "object") this.elements = [selector];
            this.length = this.elements.length;
            return this;
        },

        /**
         * Метод exist проверяет наличие первого элемента в массиве, хранящемся в свойстве elements.
         *
         * Этот метод не принимает параметров. Он проверяет, является ли первый элемент массива elements объектом.
         * Возвращаемое значение указывает на наличие (или отсутствие) элемента в массиве: если первый элемент является
         * объектом, метод возвращает true, в противном случае - false.
         *
         * @return {boolean} Возвращает true, если первый элемент массива elements является объектом, иначе возвращает false.
         */
        exist: function () {
            if(typeof this.elements[0] === "object") return true;
            else return false;
        },

        /**
         * Метод on добавляет обработчики событий к элементам, хранящимся в свойстве elements.
         *
         * @param {string} event - Тип события, на которое нужно добавить обработчик (например, 'click' или 'ready').
         * @param {function} callback - Функция-обработчик, которая будет вызвана при возникновении события.
         * @return {object} Возвращает текущий объект для дальнейшей работы с библиотекой.
         */
        on: function (event, callback) {
            if (event === 'ready') event = 'DOMContentLoaded';
            else if (event === 'swipe') {
                this.elements.forEach((el) => {
                    if (!el.swipeInitialized) {
                        swipeInit(el);
                        el.swipeInitialized = true;
                    }
                });
            }
            this.elements.forEach((el) => { el.addEventListener(event, callback); });
            return this;
        },

        /**
         * Метод off удаляет обработчики событий из элементов, хранящихся в свойстве elements.
         *
         * @param {string} event - Тип события, с которого нужно удалить обработчик (например, 'click' или 'ready').
         * @param {function} callback - Функция-обработчик, которую необходимо удалить.
         * @return {object} Возвращает текущий объект для дальнейшей работы с библиотекой.
         */
        off: function (event, callback) {
            if (event === 'ready') event = 'DOMContentLoaded';
            else if (event === 'swipe') {
                this.elements.forEach((el) => {
                    if (el.swipeInitialized) {
                        el.removeEventListener('pointerdown', handleStart);
                        el.removeEventListener('pointerup', handleEnd);
                        el.swipeInitialized = false;
                    }
                });
            }
            this.elements.forEach((el) => { el.removeEventListener(event, callback); });
            return this;
        },

        /**
         * Метод drag инициализирует обработку перетаскивания (drag) на элементах, хранящихся в свойстве elements.
         * Элементы инициализируются только один раз, чтобы избежать повторной инициализации.
         *
         * @param {function} dragStart - Функция-обработчик, вызываемая при начале перетаскивания.
         * @param {function} dragMove - Функция-обработчик, вызываемая во время перетаскивания.
         * @param {function} dragEnd - Функция-обработчик, вызываемая при завершении перетаскивания.
         */
        drag: function (dragStart, dragMove, dragEnd) {
            this.elements.forEach((el) => {
                if (!el.dragInitialized) {
                    dragInit(el, dragStart, dragMove, dragEnd);
                    el.dragInitialized = true;
                }
            });
        },

        /**
         * Метод removeDrag удаляет обработку перетаскивания (drag) с элементов, хранящихся в свойстве elements.
         * Удаление обработчиков происходит только для тех элементов, которые были инициализированы ранее.
         *
         * @return {object} Возвращает текущий объект для дальнейшей работы с библиотекой.
         */
        removeDrag: function () {
            this.elements.forEach((el) => {
                if (el.dragInitialized) {
                    el.removeEventListener('mousedown', handleStart);
                    el.removeEventListener('mousemove', handleMove);
                    el.removeEventListener('mouseup', handleEnd);
                    el.removeEventListener('touchstart', handleStart);
                    el.removeEventListener('touchmove', handleMove);
                    el.removeEventListener('touchend', handleEnd);
                    el.removeEventListener('touchcancel', handleEnd);
                    el.dragInitialized = false;
                }
            });
            return this;
        },

        /**
         * Инициализирует обработчик события прокрутки для каждого элемента, который еще не был инициализирован, и
         * проверяет, видим ли элемент в текущей области видимости. Вызывает функции обратного вызова при входе или
         * выходе элемента из области видимости.
         *
         * @param {function} enteredViewport - Функция обратного вызова, которая вызывается, когда элемент входит в
         *     область видимости.
         * @param {function} exitedViewport - Функция обратного вызова, которая вызывается, когда элемент выходит из
         *     области видимости.
         */
        isVisible: function (enteredViewport, exitedViewport) {
            this.elements.forEach((el) => {
                if (!el.isVisibleInitialized) {
                    isVisibleInit(el, enteredViewport, exitedViewport);
                    el.isVisibleInitialized = true;
                }
            });
        },

        /**
         * Удаляет обработчик события прокрутки для каждого элемента, у которого он был инициализирован.
         *
         * @return {object} Возвращает текущий объект для обеспечения цепочки вызовов.
         */
        removeIsVisible: function () {
            this.elements.forEach((el) => {
                if (el.isVisibleInitialized) {
                    window.removeEventListener('scroll', handleScroll);
                    el.isVisibleInitialized = false;
                }
            });
            return this;
        },

        /**
         * Метод load отправляет асинхронный HTTP-запрос и обрабатывает ответ.
         *
         * @param {object} options - Объект с параметрами запроса и обработки ответа.
         * @param {string} options.url - URL-адрес, по которому будет отправлен запрос.
         * @param {string} [options.method="POST"] - HTTP-метод запроса (например, "GET", "POST", "PUT" или "DELETE").
         * @param {object} [options.headers] - Объект с заголовками запроса.
         * @param {*} options.data - Данные для отправки на сервер.
         * @param {boolean} [options.append] - Если true, то ответ будет добавлен в конец элемента.
         * @param {boolean} [options.prepend] - Если true, то ответ будет добавлен в начало элемента.
         * @param {function} [options.success] - Функция-обработчик, вызываемая при успешном выполнении запроса.
         */
        load: function (options) {
            const xhr = new XMLHttpRequest();
            xhr.open(options.method || "POST", options.url, true);
            xhr.setRequestHeader('Cache-Control', 'no-cache');

            if (options.headers) {
                for (const header in options.headers) { xhr.setRequestHeader(header, options.headers[header]); }
            }

            xhr.addEventListener("readystatechange", () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const response = document.createElement("div");
                    response.innerHTML = xhr.responseText;
                    if (options.append === true) this.elements[0].insertAdjacentHTML("beforeend", xhr.responseText);
                    else if (options.prepend === true) this.elements[0].insertAdjacentHTML("afterbegin", xhr.responseText);
                    if (typeof options.success === 'function') options.success(response.childNodes);
                }
            });

            xhr.send(options.data);
        },

        /**
         * Метод hover добавляет обработчики событий 'mouseover' и 'mouseout' к элементам, хранящимся в свойстве
         * elements.
         *
         * @param {function} mouseOver - Функция-обработчик, вызываемая при наведении указателя мыши на элемент.
         * @param {function} mouseOut - Функция-обработчик, вызываемая при уходе указателя мыши с элемента.
         * @param {boolean|object} [options=false] - Дополнительные параметры для обработчиков событий.
         */
        hover: function (mouseOver, mouseOut, options = false) {
            this.elements.forEach(function (el) {
                el.addEventListener('mouseover', mouseOver, options);
                el.addEventListener('mouseout', mouseOut, options);
            });
        },

        /**
         * Метод each выполняет функцию-обработчик для каждого элемента, хранящегося в свойстве elements.
         *
         * @param {function} callback - Функция-обработчик, которая будет вызвана для каждого элемента массива
         *     elements.
         *                              Обработчик вызывается с индексом элемента в массиве и контекстом, установленным
         *     на текущий элемент.
         * @return {object} Возвращает текущий объект для дальнейшей работы с библиотекой.
         */
        each: function (callback) {
            this.elements.forEach(function (el, index) {
                let boundCallback = callback.bind(el);
                boundCallback(index);
            });
            return this;
        },

        /**
         * Метод add создает новый элемент с указанным тегом и добавляет его в первый элемент массива elements.
         *
         * @param {string} tagName - Название тега нового элемента (например, "div", "span" или "p").
         * @return {object} Возвращает объект, созданный с помощью метода init, примененного к новому элементу.
         */
        add: function (tagName) {
            if (tagName && this.elements[0]) {
                let el = document.createElement(tagName);
                this.elements[0].appendChild(el);
                return this.init(el);
            }
        },

        /**
         * Метод remove удаляет все элементы, хранящиеся в свойстве elements, из их родительских элементов.
         *
         * @return {object} Возвращает текущий объект для дальнейшей работы с библиотекой.
         */
        remove: function () {
            // Удаление элемента
            this.elements.forEach(function (el) { el.parentNode.removeChild(el); });
            return this;
        },

        /**
         * Метод hasClass проверяет, имеет ли первый элемент массива elements указанный класс.
         *
         * @param {string} className - Имя класса для проверки наличия у первого элемента массива elements.
         * @return {boolean} Возвращает true, если первый элемент массива имеет указанный класс, иначе false.
         */
        hasClass: function (className) {
            if (className && this.elements[0]) return this.elements[0].classList.contains(className);
        },

        /**
         * Метод addClass добавляет указанный класс ко всем элементам, хранящимся в свойстве elements.
         *
         * @param {string} className - Имя класса, которое будет добавлено к каждому элементу массива elements.
         * @return {object} Возвращает текущий объект для дальнейшей работы с библиотекой.
         */
        addClass: function (className) {
            if (className && this.elements[0]) {
                this.elements.forEach(function (el) { el.classList.add(className); });
            }
            return this;
        },

        /**
         * Метод removeClass удаляет указанный класс у всех элементов, хранящихся в свойстве elements.
         *
         * @param {string} className - Имя класса, которое будет удалено у каждого элемента массива elements.
         * @return {object} Возвращает текущий объект для дальнейшей работы с библиотекой.
         */
        removeClass: function (className) {
            if (className && this.elements[0]) {
                this.elements.forEach(function (el) { el.classList.remove(className); });
            }
            return this;
        },

        /**
         * Метод hasAttr проверяет, имеет ли первый элемент массива elements указанный атрибут.
         *
         * @param {string} attribute - Имя атрибута для проверки наличия у первого элемента массива elements.
         * @return {boolean} Возвращает true, если первый элемент массива имеет указанный атрибут, иначе false.
         */
        hasAttr: function (attribute) {
            if (attribute && this.elements[0]) return this.elements[0].hasAttribute(attribute);
        },

        /**
         * Метод attr получает или устанавливает значение указанного атрибута для элементов, хранящихся в свойстве
         * elements.
         *
         * @param {string} attribute - Имя атрибута для получения или установки значения.
         * @param {string|boolean} [value=false] - Значение атрибута для установки или false для получения значения
         *     атрибута. Если значение равно пустой строке, атрибут будет удален у всех элементов массива.
         * @return {*} Если value равно false, возвращает значение атрибута для первого элемента массива elements.
         *             В противном случае, если установлено значение, метод не возвращает ничего.
         */
        attr: function (attribute, value = false) {
            if (attribute && this.elements[0]) {
                if (typeof value == "number") value = value + "";
                if (value) value = value.trim();
                if (typeof value == "boolean") return this.elements[0].getAttribute(attribute);
                else if (typeof value == "string") {
                    this.elements.forEach(function (el) {
                        if (value === '') el.removeAttribute(attribute, value);
                        else el.setAttribute(attribute, value);
                    });
                }
            }
        },

        /**
         * Метод attrList возвращает объект, содержащий все атрибуты и их значения для первого элемента массива
         * elements.
         *
         * @return {object|boolean} Возвращает объект с парами имя-значение атрибутов для первого элемента массива
         *     elements или false, если массив elements пуст.
         */
        attrList: function () {
            if (this.elements[0]) {
                const element = this.elements[0];
                const attributes = element.attributes;
                const attrList = {};
                for (let i = 0; i < attributes.length; i++) {
                    const attr = attributes[i];
                    attrList[attr.name] = attr.value;
                }
                return attrList;
            }
            return false;
        },

        /**
         * Метод parent возвращает родительский элемент или ближайший родительский элемент с указанным классом
         * для первого элемента массива elements.
         *
         * @param {string|boolean} [className=false] - Если указано имя класса, метод вернет ближайший родительский
         *     элемент с этим классом. Если указано значение false, метод вернет непосредственный родительский элемент.
         * @return {object} Возвращает объект, созданный с помощью метода init, примененного к найденному родительскому
         *     элементу.
         */
        parent: function (className = false) {
            if (this.elements[0]) {
                if (!className) return this.init(this.elements[0].parentNode);
                else return this.init(this.elements[0].closest(className));
            }
        },

        /**
         * Метод children возвращает дочерний элемент первого элемента массива elements, основываясь на переданных
         * аргументах.
         *
         * @param {number|string} classNameOrNodeChild - Если указано число, метод вернет дочерний элемент с указанным
         *     индексом. Если указано имя класса, метод вернет первый найденный дочерний элемент с указанным классом.
         * @return {object} Возвращает объект, созданный с помощью метода init, примененного к найденному дочернему
         *     элементу.
         */
        children: function (classNameOrNodeChild) {
            if (this.elements[0]) {
                if (typeof classNameOrNodeChild === "number") {
                    return this.init(this.elements[0].children[classNameOrNodeChild]);
                }
                else if (typeof classNameOrNodeChild === "string") {
                    // возращаем первый найденный элемент, соответствующий условию
                    return this.init(this.elements[0].querySelector(classNameOrNodeChild));
                }
            }
        },

        /**
         * Метод width возвращает ширину первого элемента массива elements, включая внешние отступы и границы.
         *
         * @return {number} Возвращает ширину первого элемента массива elements или undefined, если массив elements
         *     пуст.
         */
        width: function () { if (this.elements[0]) return this.elements[0].offsetWidth; },

        /**
         * Метод height возвращает высоту первого элемента массива elements, включая внешние отступы и границы.
         *
         * @return {number} Возвращает высоту первого элемента массива elements или undefined, если массив elements
         *     пуст.
         */
        height: function () { if (this.elements[0]) return this.elements[0].offsetHeight; },

        /**
         * Метод getBounds возвращает объект с размерами и позицией первого элемента массива elements относительно окна
         * просмотра.
         *
         * @return {object|boolean} Возвращает объект с размерами и позицией первого элемента массива elements
         *     относительно окна просмотра или false, если массив elements пуст.
         */
        getBounds: function () {
            if (this.elements[0]) return this.elements[0].getBoundingClientRect();
            return false;
        },

        /**
         * Метод val получает или устанавливает значение атрибута value для элементов, хранящихся в свойстве elements.
         *
         * @param {string} [value] - Значение атрибута value для установки. Если не указано, метод вернет значение
         *     атрибута value для первого элемента массива elements.
         * @return {object|string} Возвращает текущий объект для дальнейшей работы с библиотекой, если указано значение
         *     для установки. В противном случае, возвращает значение атрибута value для первого элемента массива
         *     elements.
         */
        val: function (value) {
            if (this.elements[0]) {
                if (value === undefined) return this.elements[0].value;
                else this.elements.forEach(function (el) { el.value = value; });
            }
            return this;
        },

        /**
         * Метод files возвращает файлы, связанные с первым элементом в свойстве elements.
         *
         * Этот метод проверяет наличие файлов, связанных с первым элементом в массиве elements. Если файлы отсутствуют,
         * метод возвращает null. В противном случае, возвращает объект FileList, содержащий файлы, связанные с первым
         * элементом.
         *
         * @return {FileList|null} Возвращает объект FileList с файлами первого элемента, если они существуют.
         * В противном случае, возвращает null.
         */
        files: function () {
            if(this.elements[0].files.length === 0) return null;
            else return this.elements[0].files;
        },

        /**
         * Метод checked получает или устанавливает значение атрибута checked для первого элемента массива elements.
         *
         * @param {boolean} [set] - Значение атрибута checked для установки. Если не указано, метод вернет значение
         *     атрибута checked для первого элемента массива elements.
         * @return {boolean} Возвращает текущее значение атрибута checked для первого элемента массива elements или
         *                   новое значение, если указан параметр set.
         */
        checked: function (set) {
            if (this.elements[0]) {
                if (typeof set !== 'undefined') {
                    this.elements[0].checked = !!set;
                    return set;
                }
                else return this.elements[0].checked;
            }
        },

        /**
         * Метод css получает или устанавливает значение CSS свойств для элементов, хранящихся в свойстве elements.
         *
         * @param {string|object} property - Название CSS свойства или объект с парами название-значение CSS свойств.
         * @param {string} [value] - Значение CSS свойства для установки. Если не указано, метод вернет значение CSS
         *     свойства для первого элемента массива elements.
         * @return {object|string} Возвращает текущий объект для дальнейшей работы с библиотекой, если указано значение
         *     для установки. В противном случае, возвращает значение CSS свойства для первого элемента массива
         *     elements.
         */
        css: function (property, value) {
            if (property && this.elements[0]) {
                // Получение значения CSS свойства
                if (typeof property === "string" && value === undefined) {
                    return getComputedStyle(this.elements[0])[property];
                }
                // Установка значения CSS свойства
                else if (typeof property === "string" && value !== undefined) {
                    this.elements.forEach(function (el) { el.style[property] = value; });
                }
                // Установка значений нескольких CSS свойств
                else if (typeof property === "object") {
                    for (let key in property) {
                        this.elements.forEach(function (el) { el.style[key] = property[key]; });
                    }
                }
            }
            return this;
        },

        /**
         * Метод text получает или устанавливает текстовое содержимое для элементов, хранящихся в свойстве elements.
         *
         * @param {string} [textContent] - Текстовое содержимое для установки. Если не указано, метод вернет текстовое
         *     содержимое первого элемента массива elements.
         * @return {object|string} Возвращает текущий объект для дальнейшей работы с библиотекой, если указано значение
         *     для установки. В противном случае, возвращает текстовое содержимое первого элемента массива elements.
         */
        text: function (textContent) {
            if (this.elements[0]) {
                // Получение текстового содержимого элемента
                if (textContent === undefined) return this.elements[0].textContent;
                // Установка текстового содержимого элемента
                else this.elements.forEach(function (el) { el.textContent = textContent; });
            }
            return this;
        },

        /**
         * Метод html получает или устанавливает HTML содержимое для элементов, хранящихся в свойстве elements.
         *
         * @param {string} [innerHTML] - HTML содержимое для установки. Если не указано, метод вернет HTML содержимое
         *                               первого элемента массива elements.
         * @return {object|string} Возвращает текущий объект для дальнейшей работы с библиотекой, если указано значение
         *     для установки. В противном случае, возвращает HTML содержимое первого элемента массива elements.
         */
        html: function (innerHTML) {
            if (this.elements[0]) {
                // Получение HTML содержимого элемента
                if (innerHTML === undefined) return this.elements[0].innerHTML;
                // Установка HTML содержимого элемента
                else this.elements.forEach(function (el) { el.innerHTML = innerHTML; });
            }
            return this;
        },

        /**
         * Метод append добавляет содержимое в конец каждого элемента массива elements.
         *
         * @param {string|object} content - Содержимое для добавления. Может быть строкой (HTML) или объектом (DOM
         *     элемент).
         * @return {object} Возвращает текущий объект для дальнейшей работы с библиотекой.
         */
        append: function (content) {
            if (content && this.elements[0]) {
                this.elements.forEach(function (el) {
                    if (typeof content === "string") el.insertAdjacentHTML("beforeend", content);
                    else if (typeof content === "object") {
                        el.appendChild(content);
                    }
                });
            }
            return this;
        },

        /**
         * Метод prepend добавляет содержимое в начало каждого элемента массива elements.
         *
         * @param {string|object} content - Содержимое для добавления. Может быть строкой (HTML) или объектом (DOM
         *     элемент).
         * @return {object} Возвращает текущий объект для дальнейшей работы с библиотекой.
         */
        prepend: function (content) {
            if (content && this.elements[0]) {
                this.elements.forEach(function (el) {
                    // Вставка содержимого перед существующими дочерними элементами
                    if (typeof content === "string") el.insertAdjacentHTML("afterbegin", content);
                    // Вставка объекта перед существующими дочерними элементами
                    else if (typeof content === "object") el.insertBefore(content, el.firstChild);
                });
            }
            return this;
        },

        /**
         * Метод find ищет элементы, соответствующие указанному селектору, внутри каждого элемента в текущем объекте MKQuery.
         * Если передан объект, метод устанавливает его как новый элемент в текущем объекте MKQuery.
         *
         * @param {string|object} selector - Селектор для поиска или объект для установки.
         * @return {MKQuery} Возвращает текущий объект MKQuery для дальнейшей работы с библиотекой.
         */
        find: function(selector) {
            let elements = this.elements;
            if (elements.length === 0) {
                return this;  // Если нет элементов для поиска, вернуть текущий объект MKQuery
            }
            if (typeof selector === "string") this.elements = Array.from(elements[0].querySelectorAll(selector));
            else if (typeof selector === "object") this.elements = [selector];
            this.length = this.elements.length;
            return this;
        },

    };

    /**
     * Метод ajax выполняет асинхронный запрос на сервер с использованием XMLHttpRequest.
     *
     * @param {object} options - Объект с параметрами запроса.
     * @param {string} [options.method='POST'] - Метод HTTP запроса (GET, POST и т.д.).
     * @param {string} options.url - URL-адрес, по которому будет отправлен запрос.
     * @param {boolean} [options.async=true] - Определяет асинхронность запроса.
     * @param {object} [options.headers] - Объект с заголовками, которые будут отправлены в запросе.
     * @param {function} [options.success] - Функция обратного вызова, которая будет вызвана в случае успешного выполнения запроса.
     * @param {function} [options.error] - Функция обратного вызова, которая будет вызвана в случае возникновения ошибки.
     * @param {object} [options.data] - Данные, которые будут отправлены в запросе.
     */
    MKQuery.ajax = function (options) {
        const xhr = new XMLHttpRequest();
        xhr.open(options.method || "POST", options.url, options.async !== false); // Явно установить true как значение по умолчанию
        xhr.setRequestHeader('Cache-Control', 'no-cache');

        if (options.headers) {
            for (const header in options.headers) {
                xhr.setRequestHeader(header, options.headers[header]);
            }
        }

        xhr.addEventListener("readystatechange", () => {
            if (xhr.readyState === 4) { // Проверка завершения запроса
                if (xhr.status === 200) { // Успешный ответ
                    if (typeof options.success === 'function') {
                        options.success(xhr.responseText);
                    }
                } else {
                    // Ошибка HTTP
                    if (typeof options.error === 'function') {
                        options.error(xhr.status, xhr.statusText);
                    }
                }
            }
        });

        xhr.addEventListener("error", () => {
            // Обработка сетевых ошибок
            if (typeof options.error === 'function') {
                options.error(xhr.status, xhr.statusText);
            }
        });

        xhr.send(options.data);
    };


    /**
     * Метод parseHTML преобразует строку HTML в объект Document, который можно использовать для доступа к элементам DOM.
     *
     * @param {string} htmlString - Строка HTML для преобразования.
     * @return {Document} Возвращает объект Document, представляющий разобранный HTML.
     */
    MKQuery.parseHTML = function (htmlString) {
        if (typeof htmlString !== 'string') {
            throw new TypeError('Аргумент htmlString должен быть строкой');
        }
        let parser = new DOMParser();
        return parser.parseFromString(htmlString, 'text/html');
    };

    /**
     * Метод setCookie устанавливает новый cookie с заданными именем, значением и временем жизни.
     *
     * @param {string} name - Имя cookie.
     * @param {string} value - Значение cookie.
     * @param {number} [days=1] - Количество дней, на которое будет установлено cookie. По умолчанию 1 день.
     */
    MKQuery.setCookie = function (name, value, days = 1) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "; expires=" + date.toUTCString();
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    };

    /**
     * Метод getCookie возвращает значение cookie по заданному имени.
     *
     * @param {string} name - Имя cookie, значение которого нужно получить.
     * @return {string|null} Возвращает значение cookie или null, если cookie с таким именем не найдено.
     */
    MKQuery.getCookie = function (name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    };

    /**
     * Метод eraseCookie удаляет cookie с заданным именем.
     *
     * @param {string} name - Имя cookie, которое нужно удалить.
     */
    MKQuery.eraseCookie = function (name) {
        document.cookie = name + '=; Max-Age=-99999999; path=/';
    };


    /**
     * Метод getViewports Получает видимые области просмотра с учетом фиксированных и перекрывающихся элементов.
     *
     * Эта функция вычисляет прямоугольники видимых областей просмотра, учитывая
     * фиксированные и перекрывающиеся элементы в документе. Она возвращает массив
     * прямоугольников, представляющих видимые части области просмотра.
     *
     * @returns {Array} Массив видимых прямоугольников области просмотра, где каждый прямоугольник
     * является объектом со свойствами `top`, `left`, `right`, `bottom`, `width` и `height`.
     */
    MKQuery.getViewports = function () {
        const elementsInDocument = document.documentElement;
        function getScrollbarWidth() {
            const outer = document.createElement("div");
            outer.style.visibility = "hidden";
            outer.style.overflow = "scroll";
            outer.style.width = "100px";
            outer.style.height = "100px";
            document.body.appendChild(outer);
            const innerWidth = outer.clientWidth;
            const innerHeight = outer.clientHeight;
            document.body.removeChild(outer);
            const scrollbarWidth = 100 - innerWidth;
            const scrollbarHeight = 100 - innerHeight;
            return { width: scrollbarWidth, height: scrollbarHeight };
        }

        const scrollbarSize = getScrollbarWidth();
        const windowWidth = (window.innerWidth || document.documentElement.clientWidth) - scrollbarSize.width;
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight) - scrollbarSize.height;

        let viewportRect = {
            top: 0,
            left: 0,
            right: windowWidth,
            bottom: windowHeight,
            width: windowWidth,
            height: windowHeight,
        };
        function calculateRealViewport(viewport, cutViewports) {
            let visibleViewports = [{ ...viewport }];

            cutViewports.forEach(cutViewport => {
                visibleViewports = visibleViewports.flatMap(viewport => {
                    const isVerticalOverlap = cutViewport.bottom > viewport.top && cutViewport.top < viewport.bottom;
                    const isHorizontalOverlap = cutViewport.right > viewport.left && cutViewport.left < viewport.right;

                    if (isVerticalOverlap && isHorizontalOverlap) {
                        let newViewports = [];

                        if (cutViewport.top > viewport.top) {
                            newViewports.push({
                                top: viewport.top,
                                left: viewport.left,
                                right: viewport.right,
                                bottom: cutViewport.top,
                                width: viewport.width,
                                height: cutViewport.top - viewport.top,
                            });
                        }

                        if (cutViewport.bottom < viewport.bottom) {
                            newViewports.push({
                                top: cutViewport.bottom,
                                left: viewport.left,
                                right: viewport.right,
                                bottom: viewport.bottom,
                                width: viewport.width,
                                height: viewport.bottom - cutViewport.bottom,
                            });
                        }

                        if (cutViewport.left > viewport.left) {
                            newViewports.push({
                                top: Math.max(viewport.top, cutViewport.top),
                                left: viewport.left,
                                right: cutViewport.left,
                                bottom: Math.min(viewport.bottom, cutViewport.bottom),
                                width: cutViewport.left - viewport.left,
                                height: Math.min(viewport.bottom, cutViewport.bottom) - Math.max(viewport.top, cutViewport.top),
                            });
                        }

                        if (cutViewport.right < viewport.right) {
                            newViewports.push({
                                top: Math.max(viewport.top, cutViewport.top),
                                left: cutViewport.right,
                                right: viewport.right,
                                bottom: Math.min(viewport.bottom, cutViewport.bottom),
                                width: viewport.right - cutViewport.right,
                                height: Math.min(viewport.bottom, cutViewport.bottom) - Math.max(viewport.top, cutViewport.top),
                            });
                        }
                        return newViewports;
                    }
                    else return viewport;
                });
            });

            return visibleViewports;
        }
        function traverseDOM(element) {
            let result = false;
            let cutViewport = [];
            if (element.nodeType === Node.ELEMENT_NODE) {
                const children = element.children;
                for (let i = 0; i < children.length; i++) {
                    if(children[i] instanceof HTMLElement) {
                        let result = checkOverlap(children[i]);
                        if(result) cutViewport.push(result);
                        let traverseDOMResult = traverseDOM(children[i]);
                        if(traverseDOMResult) {
                            for (let j = 0; j < traverseDOMResult.length; j++) {
                                if(traverseDOMResult[j]) cutViewport.push(traverseDOMResult[j])
                            }
                        }
                    }
                }
            }
            if(cutViewport.length > 0) result = cutViewport;
            return result;
        }

        function checkOverlap(element) {
            const display = window.getComputedStyle(element).getPropertyValue('display');
            const position = window.getComputedStyle(element).getPropertyValue('position');
            const opacity = window.parseFloat(getComputedStyle(element).getPropertyValue('opacity'));
            const zIndex = window.getComputedStyle(element).getPropertyValue('z-index');
            if (display !== "none" && position === 'fixed' && zIndex !== 'auto' && opacity > 0) {
                return fixedElementInViewport(element);
            }
            else return false;
        }

        function fixedElementInViewport(element) {
            const rect = element.getBoundingClientRect();
            const inViewportVertically = rect.top + rect.height;
            const inViewportHorizontally = rect.left + rect.width;
            if(inViewportVertically > 0 && inViewportHorizontally > 0) return rect;
            else return false;
        }


        const cutViewports = traverseDOM(elementsInDocument);
        let realViewportRects = [];
        if(typeof cutViewports === "object") {
            realViewportRects = calculateRealViewport(viewportRect, cutViewports.flat());
        }
        else realViewportRects.push(viewportRect);
        if(realViewportRects.length > 0) return realViewportRects;
        else return false;
    };

    /**
     * Метод toggle для переключения различных состояний элементов
     *
     * @param {String} mode - Режим переключения: 'class', 'value', 'checked', 'select', 'visibility', 'css'
     * @param {String} param - Параметр, зависящий от выбранного режима
     *
     * Режимы:
     * - 'class': Переключение класса. Пример параметра: 'active'
     * - 'value': Переключение значения input. Пример параметра: 'value1,value2'
     * - 'checked': Переключение состояния 'checked' для input[type=checkbox]
     * - 'select': Переключение выбранного значения для элемента <select>
     * - 'visibility': Переключение видимости элемента
     * - 'css': Переключение CSS-свойств. Пример параметра: 'color:red;background-color:blue'
     *
     * @returns {MKQuery} Возвращает текущий объект MKQuery для цепочного вызова методов
     */
    MKQuery.fn.toggle = function (mode, param) {
        if (mode && this.elements[0]) {
            this.elements.forEach(function (el) {
                switch (mode) {
                    case 'class':
                        el.classList.toggle(param);
                        break;
                    case 'value':
                        let values = param.split(',');
                        el.value = el.value === values[0] ? values[1] : values[0];
                        break;
                    case 'checked':
                        el.checked = !el.checked;
                        break;
                    case 'select':
                        el.selectedIndex = (el.selectedIndex + 1) % el.options.length;
                        break;
                    case 'visibility':
                        el.style.display = (el.style.display === 'none') ? '' : 'none';
                        break;
                    case 'css':
                        const cssRules = param.split(';');
                        cssRules.forEach(rule => {
                            const [property, value] = rule.split(':');
                            el.style[property.trim()] = el.style[property.trim()] === value.trim() ? '' : value.trim();
                        });
                        break;
                    default:
                        console.error("Toggle: Invalid mode:", mode);
                }
            });
        }
        return this;
    };


    // Вспомогательная функция для инициализации события swipe
    function swipeInit(element) {
        if (typeof window.CustomEvent !== 'function') {
            window.CustomEvent = class CustomEvent extends Event {
                constructor(event, params = {bubbles: false, cancelable: false, detail: undefined}) {
                    super(event, params);
                    this.detail = params.detail;
                }
            };
        }

        const eventData = {};
        const tempData = {};
        element.ondragstart = () => false;
        element.addEventListener('pointerdown', handleStart, false);
        element.addEventListener('pointerup', handleEnd, false);
        element.style["touch-action"] = "none";
        element.swipe = true;

        // Запоминаем начальные координаты при касании
        function handleStart(e) {
            tempData.startY = e.clientY;
            tempData.startX = e.clientX;
        }

        // Запоминаем конечные координаты после завершения касания
        function handleEnd(e) {
            tempData.endY = e.clientY;
            tempData.endX = e.clientX;
            eventData.MoveY = tempData.startY - tempData.endY;
            eventData.MoveX = tempData.startX - tempData.endX;

            // Определение направления свайпа с учетом наибольшего смещения
            if (Math.abs(eventData.MoveY) > Math.abs(eventData.MoveX)) {
                eventData.direction = eventData.MoveY > 0 ? "up" : "down";
            }
            else eventData.direction = eventData.MoveX > 0 ? "left" : "right";

            // Уточнение вертикального и горизонтального направлений
            eventData.dirY = tempData.startY > tempData.endY ? "up" : (tempData.startY < tempData.endY ? "down" : "none");
            eventData.dirX = tempData.startX > tempData.endX ? "left" : (tempData.startX < tempData.endX ? "right" : "none");

            // Если смещение больше порога, вызываем событие свайпа
            if (Math.abs(eventData.MoveY) > 20 || Math.abs(eventData.MoveX) > 20) {
                element.dispatchEvent(new CustomEvent('swipe', { bubbles: true, cancelable: true, detail: eventData }));
            }
        }
    }

    // Вспомогательная функция для инициализации события drag & drop
    function dragInit(element, dragStart, dragMove, dragEnd) {
        const eventData = {};
        const tempData = {};

        element.ondragstart = () => false;
        element.addEventListener('mousedown', handleStart, false);
        element.addEventListener('mousemove', handleMove, false);
        element.addEventListener('mouseup', handleEnd, false);
        element.addEventListener('touchstart', handleStart, false);
        element.addEventListener('touchmove', handleMove, false);
        element.addEventListener('touchend', handleEnd, false);
        element.addEventListener('touchcancel', handleEnd, false);

        element.style["touch-action"] = "none";

        function handleStart(e) {
            const isTouchEvent = e.type === 'touchstart';
            tempData.startY = isTouchEvent ? e.touches[0].clientY : e.clientY;
            tempData.startX = isTouchEvent ? e.touches[0].clientX : e.clientX;
            eventData.startY = tempData.startY;
            eventData.startX = tempData.startX;

            dragStart && dragStart(new CustomEvent('dragstart', {bubbles: true, cancelable: true, detail: eventData}));
        }

        function handleMove(e) {
            if (tempData.startX === undefined || tempData.startY === undefined) return;

            const isTouchEvent = e.type === 'touchmove';
            eventData.MoveY = (isTouchEvent ? e.touches[0].clientY : e.clientY) - tempData.startY;
            eventData.MoveX = (isTouchEvent ? e.touches[0].clientX : e.clientX) - tempData.startX;
            eventData.currentX = isTouchEvent ? e.touches[0].clientX : e.clientX;
            eventData.currentY = isTouchEvent ? e.touches[0].clientY : e.clientY;

            dragMove && dragMove(new CustomEvent('dragmove', {bubbles: true, cancelable: true, detail: eventData}));
        }

        // Обрабатываем конец перетаскивания элемента
        function handleEnd(e) {
            if (tempData.startX === undefined || tempData.startY === undefined) return;

            const isTouchEvent = e.type === 'touchend' || e.type === 'touchcancel';
            eventData.MoveY = (isTouchEvent ? e.changedTouches[0].clientY : e.clientY) - tempData.startY;
            eventData.MoveX = (isTouchEvent ? e.changedTouches[0].clientX : e.clientX) - tempData.startX;
            eventData.endY = isTouchEvent ? e.changedTouches[0].clientY : e.clientY;
            eventData.endX = isTouchEvent ? e.changedTouches[0].clientX : e.clientX;

            dragEnd && dragEnd(new CustomEvent('dragend', {bubbles: true, cancelable: true, detail: eventData}));

            // Сброс начальных значений
            tempData.startX = undefined;
            tempData.startY = undefined;
        }
    }

    const alert = window.alert;


    /**
     * Загружает скрипт (JS) или стилевой файл (CSS) на страницу ровно один раз.
     * Если файл уже загружен, функция немедленно вызывает обратный вызов, не добавляя файл повторно.
     *
     * @param {string} url URL-адрес файла для загрузки. Это может быть путь к JavaScript-файлу или CSS-файлу.
     * @param {'js'|'css'} type Тип загружаемого ресурса: 'js' для JavaScript или 'css' для стилевого файла.
     * @param {Function} [callback] Функция обратного вызова, которая будет вызвана после успешной загрузки файла.
     *                              Для JavaScript-файлов вызов произойдет после события onload нового элемента script.
     *                              Для CSS-файлов обратный вызов происходит немедленно, поскольку нет стандартного способа
     *                              отслеживать завершение загрузки CSS через <link>.
     *
     * @example
     * require_once('https://example.com/style.css');
     *
     * require_once('https://example.com/script.js', 'js', function() {
     *   console.log('Скрипт загружен и выполнен.');
     * });
     */
    function require_once(url, type, callback) {
        let existingElement;

        if (type === 'js') {
            existingElement = document.querySelector(`script[src="${url}"]`);
        }
        else if (type === 'css') {
            existingElement = document.querySelector(`link[href="${url}"]`);
        }

        if (existingElement) {
            if (callback) callback();
            return;
        }

        let newElement;

        if (type === 'js') {
            newElement = document.createElement('script');
            newElement.type = 'text/javascript';
            newElement.src = url;
            newElement.async = false; // это обеспечит последовательную загрузку
        }
        else if (type === 'css') {
            newElement = document.createElement('link');
            newElement.rel = 'stylesheet';
            newElement.type = 'text/css';
            newElement.href = url;
        }

        if (callback && type === 'js') {
            newElement.onload = callback;
        }

        document.head.appendChild(newElement);
    }
    // Делаем require_once глобально доступной
    window.require_once = require_once;


    // Вспомогательная функция для инициализации события isVisible
    function isVisibleInit(element, enteredViewport, exitedViewport) {
        let wasVisible = false;
        window.addEventListener('scroll', handleScroll, false);

        function isInViewport(el) {
            const visibleViewports = MKQuery.getViewports();
            const rect = el.getBoundingClientRect();

            if(typeof visibleViewports === "object") {
                return visibleViewports.some(viewport => {
                    const inViewportVertically = (rect.top >= viewport.top && rect.top < viewport.bottom) ||
                        (rect.bottom > viewport.top && rect.bottom <= viewport.bottom) ||
                        (rect.top <= viewport.top && rect.bottom >= viewport.bottom);

                    const inViewportHorizontally = (rect.left >= viewport.left && rect.left < viewport.right) ||
                        (rect.right > viewport.left && rect.right <= viewport.right) ||
                        (rect.left <= viewport.left && rect.right >= viewport.right);

                    return inViewportVertically && inViewportHorizontally;
                });
            }
            else return  false;
        }

        function handleScroll() {
            const isVisible = isInViewport(element);

            if (isVisible && !wasVisible) enteredViewport && enteredViewport();
            else if (!isVisible && wasVisible) exitedViewport && exitedViewport();

            wasVisible = isVisible;
        }
    }

    const eventNames = [
        "ready", "scroll", "input", "swipe", "blur", "change", "click", "copy", "cut", "paste",
        "dblclick", "error", "focus", "input", "invalid", "keydown", "keypress", "keyup",
        "mousedown", "mouseenter", "mouseleave", "mousemove", "mouseout", "mouseover", "mouseup",
        "mousewheel", "wheel", "pause", "play", "playing", "progress", "reset", "resize", "scroll", "search",
        "select", "show", "submit", "unload", "touchcancel", "touchend", "touchmove", "touchstart", "transitionend"
    ];

    const handler = {
        get: function (target, propName) {
            if (target[propName]) {
                return target[propName];
            }
            else if (eventNames.includes(propName)) {
                return function (callback) {
                    return this.on(propName, callback);
                };
            }
            return undefined;
        },
    };

    MKQuery.fn.init.prototype = new Proxy(MKQuery.fn, handler);
    window.MK = window.MKQuery = MKQuery;
})(window);